import TYPES from '@/types';

// Application
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';

// Domain
import Inject from '@/modules/shared/domain/di/inject';

export default class StartViewModel {
  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  private readonly get_person_query!: GetPersonQueryService;

  readonly i18n_namespace = 'components.contract-savings.start';

  small_screen: null | boolean = null;

  can_continue = false;

  is_loading = true;

  get user_name() {
    const person = this.get_person_query.execute();
    return person.name;
  }
}
